<template>
  <div class="loading" v-show="loadShow || false">
    <div class="loadBox">
      <i class="el-icon-loading"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: ["loadShow"],
};
</script>

<style lang="less" scoped>
.loading {
  position: absolute;
  width: calc(100% - 20px);
  height: 100%;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.7);
  .loadBox {
    min-height: 400px;
    line-height: 400px;
    text-align: center;
  }
  i {
    font-size: 30px;
    color: #0091ff;
  }
}
</style>
