<template>
    <div class="addressInfo">
        <el-button type="primary" class="mtb10" @click="addTpInfo('')">+ 新增贴牌商</el-button>
        <div class="infoBox">
            <div class="table-data">
                <TableLoading :loadShow="loadShow"></TableLoading>
                <el-table :data="tableData" border>
                    <el-table-column prop="index" label="序号" width="70"></el-table-column>
                    <el-table-column prop="logo_img" label="图片">
                        <template slot-scope="scope">
                            <img :src="scope.row.logo_img" style="width: 50px; height: 50px;" alt="图片">
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="贴牌商名称" min-width="100" />
                    <el-table-column prop="appid" label="appId" min-width="150" />
                    <el-table-column prop="account" label="账号" />
                    <el-table-column prop="password" label="密码" />
                    <el-table-column prop="people" label="负责人" />
                    <el-table-column prop="phone" label="手机号" />
                    <el-table-column prop="open_name" label="开户姓名" />
                    <el-table-column prop="bank_type" label="银行卡类型" />
                    <el-table-column prop="bankId" label="银行卡号" />
                    <el-table-column prop="open_bank" label="开户行" />
                    <el-table-column prop="create_time" label="创建时间" />
                    <el-table-column label="操作">
                        <template slot-scope="{ row }" v-if="row.role == 3">
                            <el-button icon="el-icon-edit" circle class="mr10" type="primary"
                                @click="editInfo(row)"></el-button>
                            <el-button icon="el-icon-delete" circle type="danger"
                                @click="deleteInfo(row.admin_id)" disabled></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pageBox">
                <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                    :page-sizes="[10]" :page-size="pageSize" :total="total">
                </el-pagination>
            </div>
        </div>

        <!-- 新增贴牌商弹窗层 -->
        <el-dialog title="新增贴牌商" :visible.sync="dialogFormVisible" width="600px">
            <el-form :model="tpInfo">
                <el-form-item label="贴牌logo：" label-width="100px" class="mtb10">
                    <el-upload :action="action" list-type="picture-card" :file-list="tpInfo.image_file"
                        :before-upload="beforeUpload" :on-remove="handleRemove" name="img_file"
                        :on-success="successUpload" :on-change="onChange">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="贴牌名称：" label-width="100px" class="mtb10">
                    <el-input v-model="tpInfo.name" autocomplete="off" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="账号：" label-width="100px" class="mtb10">
                    <el-input v-model="tpInfo.account" autocomplete="off" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="密码：" label-width="100px" class="mtb10">
                    <el-input v-model="tpInfo.password" autocomplete="off" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="负责人：" label-width="100px" class="mtb10">
                    <el-input v-model="tpInfo.people" autocomplete="off" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="开户姓名：" label-width="100px" class="mtb10">
                    <el-input v-model="tpInfo.open_name" autocomplete="off" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="开户类型：" label-width="100px" class="mtb10">
                    <el-select v-model="tpInfo.bank_type" placeholder="请选择" style="width: 80%;">
                        <el-option
                            v-for="item in bank_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="银行卡号：" label-width="100px" class="mtb10">
                    <el-input v-model="tpInfo.bankId" autocomplete="off" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="开户行：" label-width="100px" class="mtb10">
                    <el-input v-model="tpInfo.open_bank" autocomplete="off" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="联系手机号：" label-width="100px" class="mtb10">
                    <el-input v-model="tpInfo.phone" autocomplete="off" style="width: 80%;" maxlength="11"
                        minlength="11"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addTpInfo('info')" class="ml10">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    GetTpInfoApi,
    AddTpInfoApi,
    EditTpInfoApi,
} from "@/common/api";
import TableLoading from "@/components/index/TableLoading.vue";
import { BASE_URL } from "@/common/config"
export default {
    data() {
        return {
            loadShow: false,
            pageNum: 1, // 当前页数
            total: 0, // 总页数
            pageSize: 10, // 每页显示条数
            tableData: [],
            dialogFormVisible: false,
            tpInfo: {
                admin_id: '',
                name: '',
                logo_img: '',
                image_file: [],
                theme_color: '',
                account: '',
                password: '',
                people: '',
                phone: '',
                due_time: '',
                open_name: '',
                bank_type: '',
                bankId: '',
                open_bank: '',
            },
            action: BASE_URL + '/myapp/upload_image',    //上传logo请求地址
            bank_options: [
                {
                    label: '中国银行',
                    value: '中国银行'
                },
                {
                    label: '建设银行',
                    value: '建设银行'
                },
                {
                    label: '工商银行',
                    value: '工商银行'
                },
                {
                    label: '福建海峡银行',
                    value: '福建海峡银行'
                },
                {
                    label: '农业银行',
                    value: '农业银行'
                },
                {
                    label: '民生银行',
                    value: '民生银行'
                },
            ]
        };
    },
    components: {
        TableLoading,
    },
    async created() {
        this.getTableData();
    },
    methods: {
        beforeUpload(file) {
            // this.tpInfo.image_file = []
            // this.tpInfo.image_file.push({
            //     name: file.name,
            //     url: URL.createObjectURL(file),
            //     type: 'image',
            //     status: 'success'
            // })
            // 返回file，这个钩子函数就能正确将file文件放入file-list中
            return file
        },
        // 上传过程中的钩子函数，限制上传数量，直接将后面上传文件覆盖上一次的文件
        onChange(_file, fileList) {
            this.tpInfo.image_file = [fileList[fileList.length - 1]]
        },
        // 移除logo
        handleRemove() {
            this.tpInfo.image_file = []
            this.tpInfo.logo_img = ""
        },
        successUpload(item) {
            if (item.code == 500) {
                this.$message.error(`${item.msg}`);
            } else if (item.code == 200) {
                this.tpInfo.logo_img = item.data.image_name
            }
        },
        valid() {
            if (this.tpInfo.logo_img == '') {
                this.$message.error('logo不能为空');
                return false
            }
            if (this.tpInfo.name == '') {
                this.$message.error('名称不能为空');
                return false
            }
            if (this.tpInfo.account == '') {
                this.$message.error('账号不能为空');
                return false
            }
            if (this.tpInfo.password == '') {
                this.$message.error('密码不能为空');
                return false
            }
            if (this.tpInfo.phone.length > 0 && this.tpInfo.phone.length < 11) {
                this.$message.error('请正确填写手机号');
                return false
            }
            return true
        },
        // 添加贴牌信息
        async addTpInfo(type) {
            if (type == 'info') {
                this.valid()
                let res
                if (!!this.tpInfo.admin_id) {
                    // 编辑贴牌商
                    res = await EditTpInfoApi({
                        admin_id: this.tpInfo.admin_id,
                        name: this.tpInfo.name,
                        logo_img: this.tpInfo.logo_img.split('/').pop(),
                        theme_color: this.tpInfo.theme_color,
                        account: this.tpInfo.account,
                        password: this.tpInfo.password,
                        people: this.tpInfo.people,
                        phone: this.tpInfo.phone,
                        due_time: this.tpInfo.due_time,
                        open_name: this.tpInfo.open_name,
                        bank_type: this.tpInfo.bank_type,
                        bankId: this.tpInfo.bankId,
                        open_bank: this.tpInfo.open_bank,
                    });
                } else {
                    // 添加贴牌商
                    res = await AddTpInfoApi({
                        name: this.tpInfo.name,
                        logo_img: this.tpInfo.logo_img,
                        theme_color: this.tpInfo.theme_color,
                        account: this.tpInfo.account,
                        password: this.tpInfo.password,
                        people: this.tpInfo.people,
                        phone: this.tpInfo.phone,
                        due_time: this.tpInfo.due_time,
                        open_name: this.tpInfo.open_name,
                        bank_type: this.tpInfo.bank_type,
                        bankId: this.tpInfo.bankId,
                        open_bank: this.tpInfo.open_bank,
                    });
                }
                if (!res) return;
                this.getTableData();
                this.dialogFormVisible = false
            } else {
                // 打开填写贴牌商的弹窗
                this.tpInfo = {
                    admin_id: '',
                    name: '',
                    logo_img: '',
                    image_file: [],
                    theme_color: '',
                    account: '',
                    password: '',
                    people: '',
                    phone: '',
                    due_time: '',
                    open_name: '',
                    bank_type: '',
                    bankId: '',
                    open_bank: '',
                }
                this.dialogFormVisible = true
            }
        },
        // 每页条数改变时
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize = val;
        },
        // 当前页改变时
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getTableData();
        },

        // 获取表格数据
        async getTableData(page) {
            if (page) this.pageNum = page;
            this.loadShow = true;
            let res = await GetTpInfoApi({
                page: this.pageNum,
                need: this.pageSize
            });
            this.loadShow = false;
            if (!res) return;
            res.data.forEach((element, index) => {
                element.index = index + 1 + (this.pageNum - 1) * 10;
            });
            this.tableData = res.data;
            this.total = res.total;
        },
        // 删除
        deleteInfo(admin_id) {
            this.$confirm('是否要删改该贴牌商?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            })
        },
        // 编辑
        editInfo(row) {
            this.tpInfo = {
                admin_id: row.admin_id,
                name: row.name,
                logo_img: row.logo_img,
                image_file: [{
                    size: 104837,
                    name: row.logo_img,
                    type: 'image',
                    url: row.logo_img,
                }],
                theme_color: row.theme_color,
                account: row.account,
                password: row.password,
                people: row.people,
                phone: row.phone,
                due_time: row.due_time,
                open_name: row.open_name,
                bank_type: row.bank_type,
                bankId: row.bankId,
                open_bank: row.open_bank,
            }
            this.dialogFormVisible = true
        }
    },
};
</script>

<style lang="less" scoped>
.mainData {
    display: flex;
    justify-content: space-between;
    color: #555;

    img {
        vertical-align: bottom;
        margin-right: 5px;
    }
}

.avatar {
    width: 30px !important;
    height: 30px !important;
    vertical-align: bottom;
    margin-right: 7px;
}
</style>